@import "~bootstrap/dist/css/bootstrap.min.css";

html,
body,
#root {
  display: block;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  font-family: "Cerebri Sans", Avenir, Helvetica, Segoe UI, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
}

.c-header {
  background-color: #aeb5b1;
  margin-bottom: 1rem;
  padding: 1rem;
  color: white;
}
.c-header > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .c-header > div {
    flex-direction: row;
  }
}
.c-header a {
  color: white;
}

/* CSS page transitions */
.fade-enter {
  opacity: 0.01;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.fade-exit {
  opacity: 0;
}
.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 0.3s ease-in;
}
